import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import requiredAuth from '../utils/requireAuth';

const IndexPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-home justify-center items-center">
      <div className="flex flex-col">
        <div className="m-0 font-bold text-center">
          <h1 className="text-4xl mb-6">{t('pages/index/title')}</h1>
          <h2 className="text-4xl mb-12">{t('pages/index/subTitle')}</h2>
        </div>
        <div className=" block w-1/2 m-auto text-center">
          {t('pages/index/content')}
        </div>
      </div>
    </div>
  );
};

export default requiredAuth(IndexPage, { layout: true });
